import React, { useState, useEffect, useRef } from 'react';
import i18n from '../../i18n';
import './LanguageToggle.css'; 

const LanguageToggle = () => {
  const [language, setLanguage] = useState(i18n.language || 'es');
  const [isOpen, setIsOpen] = useState(false);

  // Use ref to detect clicks outside the dropdown
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Listen to i18n language changes
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };
    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup listener
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleToggle = (newLanguage) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    setIsOpen(false);
  };

  return (
    <div className="language-toggle" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="language-toggle__button"
      >
        {language.toUpperCase()}
      </button>

      {isOpen && (
        <ul className="language-toggle__menu">
          <li className="language-toggle__menu-item" onClick={() => handleToggle('en')}>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg"
              alt="English flag"
              className="language-toggle__flag"
            />
            EN
          </li>
          <li className="language-toggle__menu-item" onClick={() => handleToggle('es')}>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg"
              alt="Spanish flag"
              className="language-toggle__flag"
            />
            ES
          </li>
          <li className="language-toggle__menu-item" onClick={() => handleToggle('fr')}>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg"
              alt="French flag"
              className="language-toggle__flag"
            />
            FR
          </li>
        </ul>
      )}
    </div>
  );
};

export default LanguageToggle;
